<script lang="ts">
    import type { SvelteToastOptions } from '@zerodevx/svelte-toast/stores';

    import { SvelteToast } from '@zerodevx/svelte-toast';

    export let options: SvelteToastOptions = {
        theme: {
            '--toastWidth': '300px',
            '--toastBackground': 'transparent',
            '--toastMargin': '0.5rem 0 0 0',
            '--toastProgressBackground': '#D1D5DB',
            '--toastBarHeight': '4px',
            '--toastBarBottom': '1px',
            '--toastPadding': 0,
            '--toastMsgPadding': 0,
            '--toastColor': 'currentColor',
            '--toastBoxShadow': 'none',
            '--toastBorderRadius': '4px',
        },
        intro: { y: -64 },
    };
</script>

<div class="svelte-toast-wrapper">
    <SvelteToast {options} />
</div>

<style lang="postcss">
    .svelte-toast-wrapper {
        --toastContainerTop: auto; /* 상단에서 32px, toast의 기본 상단 여백 8px을 제외한 26px(1.5rem)로 설정 */
        --toastContainerRight: 1.5rem;
        --toastContainerBottom: 1.5rem;
        --toastContainerLeft: auto;
    }
</style>
