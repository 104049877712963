<script lang="ts">
    import '$src/app.postcss';
    import { onMount, tick } from 'svelte';
    import Loader from '$components/common/Loader.svelte';
    import { browser, building } from '$app/environment';
    import { PUBLIC_BUILD_NAME } from '$env/static/public';
    import { beforeNavigate, afterNavigate } from '$app/navigation';
    import { page } from '$app/stores';
    import { fade, blur } from 'svelte/transition';
    import { cubicIn, cubicOut } from 'svelte/easing';
    import { modal } from '$stores/modalStore';
    import { Modal, FullModal } from '$components/basic';
    import { pwaInfo } from 'virtual:pwa-info';
    import ResizeObserver from 'resize-observer-polyfill';
    import Toast from '$components/toasts/Toast.svelte';
    import { isEqual } from 'lodash-es';
    import { isLoadingOverlay } from '$stores/loadingStore';
    import { hideLoadingOverlay, showLoadingOverlay, clearAbortedOverlayCount } from '$lib/utils';
    import 'core-js/proposals/change-array-by-copy'; // polyfill for https://github.com/tc39/proposal-change-array-by-copy'
    import 'core-js/modules/web.structured-clone.js'; // polyfill for structuredClone
    import 'core-js/proposals/relative-indexing-method'; // polyfill for array.at, string.at...

    if (browser) window.ResizeObserver = ResizeObserver; // Polyfill

    let loaded = false; // 페이지 첫 로딩시 flashing 현상 감추기
    let ReloadPrompt: any; // PWA 리로드를 체크하는 프롬프트
    onMount(async () => {
        loaded = true;
        pwaInfo && (ReloadPrompt = (await import('$components/pwa/ReloadPrompt.svelte')).default);
    });

    $: webManifest = pwaInfo ? pwaInfo.webManifest.linkTag : '';

    // 아래는 transition animation 효과를 위한 코드
    // https://joshcollinsworth.com/blog/sveltekit-page-transitions
    export let data;

    // TODO: https://github.com/sveltejs/kit/issues/9868
    if (data.savedState) {
        history.replaceState(
            {
                ...data.savedState,
            },
            '',
        );
    }

    $: ({ pathname, savedState } = data);
    const transitionOut = { easing: cubicIn, duration: 10 }; /* out:fade|global={transitionOut} 을 설정하면 svelte의 오류인지 페이지 전환후 기존의 로직들이 중복실행됨 */

    // 페이지 전환시 beforeNavigate.to 가 afterNavigate.to 와 항상 같지는 않다. 예를들어 라우팅 경로 하위의 디폴트 경로로 이동하게 되는 경우가 그렇다.
    let lastBeforeNavigateFrom;
    let lastBeforeNavigateTo;
    beforeNavigate(async ({ from, to, willUnload, type }) => {
        if (__DEBUG_LOADING_OVERLAY__) {
            console.groupCollapsed(`Navigate Root Layout ${from?.url.pathname} -> ${to?.url.pathname}`);
            console.log('beforeNavigate', { from, to, willUnload, type });
        }

        $modal.forEach(m => {
            if (m.type !== 'fullcustom') {
                modal.close(m.id, false);
            }
        });

        if (to?.route.id && (to?.route.id !== from?.route.id || !isEqual(to?.params, from?.params))) {
            lastBeforeNavigateFrom = from;
            lastBeforeNavigateTo = to;
            showLoadingOverlay();

            await tick(); // beforeNavigate의 경우 상위페이지부터 호출되기 때문에 다음 프레임으로 넘기기 위해 tick()을 사용한다.

            // 페이지 단위 beforeNavigate 이벤트에서 navigation을 취소하는 경우
            clearAbortedOverlayCount();
        }
    });
    afterNavigate(({ from, to, willUnload, type }) => {
        if (!from?.url || type === 'enter') {
            // 서비스 첫 진입시
        } else if (to?.route.id && (to?.route.id !== from?.route.id || !isEqual(to?.params, from?.params))) {
            hideLoadingOverlay();
        } else if (lastBeforeNavigateFrom && lastBeforeNavigateTo && from?.route.id === lastBeforeNavigateFrom.route.id && to?.route.id !== lastBeforeNavigateTo.route.id) {
            // 페이지 전환중에 페이지 전환을 취소하고 다른 페이지로 이동하는 경우
            lastBeforeNavigateFrom = undefined;
            lastBeforeNavigateTo = undefined;

            hideLoadingOverlay();
        }
        if (__DEBUG_LOADING_OVERLAY__) {
            console.log('afterNavigate', { from, to, willUnload, type });
            console.groupEnd();
        }
    });
</script>

<svelte:head>
    {#if PUBLIC_BUILD_NAME === 'prod_hanwhas'}
        <title>LEGAL H</title>
    {:else}
        <title>{$page.data.title ? `${$page.data.title} - BHSN.AI` : 'BHSN.AI 메인페이지'}</title>
    {/if}
    <link rel="stylesheet" as="style" crossorigin href="/fonts/Pretendard-1.3.9/web/static/pretendard-dynamic-subset.css" />
    <link rel="stylesheet" as="style" crossorigin href="/fonts/TimesNewerRoman/times-newer-roman.css" />
    {@html webManifest}
</svelte:head>

<!-- 페이지 전환중 로딩바 -->
{#if $isLoadingOverlay}
    <div class="fixed z-[110] flex h-screen w-screen items-center justify-center">
        <div class="flex h-12 w-12 items-center justify-center">
            <Loader />
        </div>
    </div>
{/if}

<!-- 본문 -->
<!-- <div class="h-0 min-h-screen w-full min-w-[80rem]" class:opacity-0={!loaded} class:blur={$isLoadingOverlay}> -->
<div class="h-0 min-h-screen w-full" class:opacity-0={!loaded} class:blur={$isLoadingOverlay}>
    {#key pathname}
        <div class="h-full" in:blur={{ easing: cubicIn, duration: 300 }}>
            <slot />
        </div>
    {/key}

    <!-- global functional modal -->
    {#each $modal as { Component, props, modalProps, id, type }, i (id)}
        {#if type === 'fullcustom'}
            <svelte:component this={Component} on:close={e => modal.close(id, e.detail)} on:confirm={e => modal.close(id, e.detail)} on:cancel={() => modal.close(id, false)} {...props} />
        {:else}
            <Modal open {...modalProps} on:outroend={e => modal.close(id, ['confirm', 'prompt', 'custom'].includes(type) ? false : true)} zIndexBackdrop={40 + i * 10} zIndexModal={45 + i * 10}>
                <svelte:component this={Component} on:confirm={e => modal.close(id, e.detail)} on:cancel={() => modal.close(id, false)} {...props} />
            </Modal>
        {/if}
    {/each}
</div>
<!-- global toast -->
<Toast />

<!-- PWA 리로드 프롬프트 -->
{#if ReloadPrompt}
    <svelte:component this={ReloadPrompt} />
{/if}
